<template>
  <div>
    <v-data-table
      :headers="aHeaderJobbers"
      :items="jobberList"
      :sort-desc="true"
      :search="sSearchJobbers"
      class="table-striped elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Listado de {{ modulo }}s</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="sSearchJobbers"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            v-if="tipo === 'list'"
            color="primary"
            dark
            class="mb-2"
            v-on:click="addJobber()"
          >
            Nuevo {{ modulo }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-if="modulo == 'Jobber'"
          small
          class="mr-2 cursor-pointer"
          @click="showDailyMessages(item)"
        >
          mdi-android-messages
        </v-icon>
        <v-icon
          v-if="modulo == 'Jobber'"
          small
          class="mr-2 cursor-pointer"
          @click="showCampaings(item)"
        >
          mdi-eye
        </v-icon>
        <v-icon
          v-if="tipo === 'list'"
          small
          class="mr-2"
          @click="editJobber(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="modulo == 'Jobber' && item.imei"
          small
          class="mr-2 cursor-pointer"
          @click="cleanJobberImei(item)"
        >
          mdi-cellphone
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogDailyMessages"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar flat dark color="primary" style="flex: none">
          <v-btn icon dark @click="dialogDailyMessages = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Mensajes Diarios - {{ aJobber.nombre }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="pt-2 bg-default">
          <v-row class="font-size-120 pt-1 pb-4">
            <v-col>
              <v-data-table
                :headers="dailyMessages.aHeaderDailyMessages"
                :items="dailyMessages.aDailyMessages"
                :sort-desc="true"
                :search="dailyMessages.sSearchDailyMessages"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>
                      Listado de Mensajes Diarios
                    </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="dailyMessages.sSearchDailyMessages"
                      append-icon="search"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-on:click="dailyMessages.dialogForm = true"
                    >
                      Nuevo Mensaje
                    </v-btn>
                  </v-toolbar>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dailyMessages.dialogForm" persistent max-width="50%">
      <v-card>
        <v-card-title>
          <span class="headline">Formulario de Mensaje diario</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-menu
                  ref="datepicker"
                  v-model="dailyMessages.form.datepicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dailyMessages.form.sFechaMensaje"
                      label="Fecha de Mensaje"
                      v-bind="attrs"
                      v-on="on"
                      class="m-0 p-0"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dailyMessages.form.sFechaMensajeDatepicker"
                    @input="dailyMessages.form.datepicker = false"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="dailyMessages.form.sMensaje"
                  label="Mensaje"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text v-on:click="closeDailyMessageForm">
            Cerrar
          </v-btn>
          <v-btn color="blue darken-1" text v-on:click="saveDailyMessage">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="tasks.dialogCampaigns"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar flat dark color="primary" style="flex: none">
          <v-btn icon dark @click="tasks.dialogCampaigns = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Campañas - {{ aJobber.nombre }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="pt-2 bg-default">
          <v-row class="font-size-120 pt-1 pb-4">
            <v-col>
              <v-data-table
                :headers="tasks.aHeaderCampaigns"
                :items="tasks.aCampaigns"
                :sort-desc="true"
                :search="tasks.sSearchCampanas"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Listado de Campañas</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="tasks.sSearchCampanas"
                      append-icon="search"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    small
                    class="mr-2 cursor-pointer"
                    @click="showTasks(item)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <TaskListDialog ref="TaskListDialog" :tasks="tasks.aTasks"></TaskListDialog>
    <JobberFormDialog
      ref="JobberFormDialog"
      :aJobber="aJobber"
      :modulo="modulo"
    ></JobberFormDialog>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import moment from "moment";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import {
  NEW_DAILY_MESSAGE,
  GET_DAILY_MESSAGES_BY_JOBBER_ID,
  CLEAN_JOBBER_IMEI,
} from "@/core/services/store/jobber.module";
import { GET_CAMPAIGNS_BY_JOBBER } from "@/core/services/store/campaign.module";
import {
  GET_TASK_CAMPAIGN,
  GET_TASK_FILTERED_CAMPAIGN_JOBBER,
} from "@/core/services/store/task.module";
import JobberFormDialog from "./JobberFormDialog.vue";
import TaskListDialog from "../task/TaskListDialog.vue";
export default {
  name: "JobberList",
  components: {
    JobberFormDialog,
    TaskListDialog,
  },
  props: {
    aJobbers: Array,
    modulo: String,
    tipo: String,
  },
  data() {
    return {
      jobberList: [],
      aHeaderJobbers: [
        {
          text: "Rut",
          value: "rut",
        },
        {
          text: "Nombre",
          value: "nombre",
        },
        {
          text: "Email",
          value: "correo",
        },
        {
          text: "Teléfono",
          value: "telefono",
        },
        {
          text: "Estado",
          value: "estado",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
      sSearchJobbers: "",
      aJobber: {},
      dialogDailyMessages: false,
      dailyMessages: {
        aHeaderDailyMessages: [
          {
            text: "Fecha",
            value: "fecha",
            sortable: false,
          },
          {
            text: "Mensaje",
            value: "mensaje",
            sortable: false,
          },
          {
            text: "Usuario",
            value: "nombreCreador",
            sortable: false,
          },
        ],
        aDailyMessages: [],
        sSearchDailyMessages: "",
        dialogForm: false,
        form: {
          datepicker: false,
          sFechaMensaje: "",
          sFechaMensajeDatepicker: "",
          sMensaje: "",
        },
      },
      tasks: {
        dialogCampaigns: false,
        aTasks: [],
        aCampaigns: [],
        aHeaderCampaigns: [
          {
            text: "ID",
            value: "campaignID",
          },
          {
            text: "Nombre de Campaña",
            value: "nombre",
          },
          {
            text: "Tipo de Campaña",
            value: "tipo",
          },
          {
            text: "Tareas",
            value: "tareas",
          },
          {
            text: "Dias",
            value: "dias",
          },
          {
            text: "",
            value: "actions",
            fixed: true,
            sortable: false,
          },
        ],
        sSearchCampanas: "",
        taskData: {
          taskID: "",
          mandanteNombre: "",
          campaignID: "",
          campaignName: "",
          jobberName: "",
          assignmentDate: "",
          managmentDate: "",
          estado: "",
          attributes: "",
          initDate: "",
          finalDate: "",
          tasks: "",
          days: "",
          jobs: [],
          headersTreeRows: [
            {
              text: "Órden",
              value: "ordenCampo",
              align: "center",
              sortable: false,
            },
            { text: "Pregunta - campo", value: "pregunta", sortable: false },
            { text: "Tipo campo", value: "tipoCampo", sortable: false },
            {
              text: "Órden",
              value: "ordenOpcion",
              align: "center",
              sortable: false,
            },
            { text: "Respuesta", value: "descripcion", sortable: false },
            {
              text: "Próximo campo",
              value: "ordenProximoCampo",
              align: "center",
              sortable: false,
            },
            {
              text: "Descripción pregunta",
              value: "proximoCampo",
              sortable: false,
            },
          ],
          treeRows: [],
        },
        dialogFile: false,
        fileBase64: null,
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `${this.modulo}s`, route: "jobbers" },
    ]);
  },
  methods: {
    showDailyMessages(aJobber) {
      let oVue = this;
      oVue.dialogDailyMessages = true;
      oVue.aJobber = aJobber;
      oVue.getDailyMessages(aJobber.uniqueID);
    },

    getDailyMessages(iJobberID) {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store
        .dispatch(GET_DAILY_MESSAGES_BY_JOBBER_ID, iJobberID)
        .then((aDailyMessages) => {
          oVue.dailyMessages.aDailyMessages = aDailyMessages;
          KTUtil.removeLoading();
        });
    },

    closeDailyMessageForm() {
      let oVue = this;
      oVue.dailyMessages.dialogForm = false;
      oVue.dailyMessages.form.sFechaMensaje = "";
      oVue.dailyMessages.form.sFechaMensajeDatepicker = "";
      oVue.dailyMessages.form.sMensaje = "";
    },

    async saveDailyMessage() {
      let oVue = this;

      if (oVue.dailyMessages.form.sFechaMensaje !== "") {
        if (oVue.dailyMessages.form.sMensaje !== "") {
          const aData = {
            iJobberID: oVue.aJobber.uniqueID,
            iCreatorID: oVue.currentUserPersonalInfo.usuarioID,
            sFecha: oVue.dailyMessages.form.sFechaMensaje,
            sMensaje: oVue.dailyMessages.form.sMensaje,
          };

          const aNewDailyMessageResponse = await oVue.newDailyMessage(aData);
          if (aNewDailyMessageResponse.result) {
            Swal.fire({
              title: "¡Enhorabuena!",
              text: "El mensaje fue creado correctamente",
              icon: "success",
            });
            oVue.closeDailyMessageForm();
            oVue.getDailyMessages(oVue.aJobber.uniqueID);
          } else {
            Swal.fire({
              title: "Error al crear el mensaje",
              text: aNewDailyMessageResponse.message,
              icon: "error",
            });
          }
        } else {
          Swal.fire({
            title: "Warning!",
            text: "Debe ingresar un mensaje",
            icon: "error",
          });
        }
      } else {
        Swal.fire({
          title: "Warning!",
          text: "Debe ingresar una fecha",
          icon: "error",
        });
      }
    },

    async newDailyMessage(aData) {
      let oVue = this;
      KTUtil.setLoading();

      const aResponse = await oVue.$store.dispatch(NEW_DAILY_MESSAGE, aData);

      KTUtil.removeLoading();
      return aResponse;
    },

    showCampaings(aJobber) {
      let oVue = this;
      oVue.aJobber = aJobber;
      oVue.tasks.dialogCampaigns = true;
      oVue.getCampaigns(aJobber.uniqueID);
    },

    getCampaigns(iJobberID) {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store
        .dispatch(GET_CAMPAIGNS_BY_JOBBER, iJobberID)
        .then((aCampaigns) => {
          oVue.tasks.aCampaigns = aCampaigns;
          KTUtil.removeLoading();
        });
    },

    showTasks(aCampaign) {
      let oVue = this;
      oVue.getTasks(aCampaign.campaignID, oVue.aJobber.uniqueID);
    },

    async getTasksByCampaign(iCampaignID) {
      KTUtil.setLoading();
      let oVue = this;
      const aAllJobsDataResponse = await oVue.$store.dispatch(
        GET_TASK_CAMPAIGN,
        iCampaignID
      );
      KTUtil.removeLoading();

      return aAllJobsDataResponse;
    },

    getTasks(iCampaignID, iJobberID) {
      let oVue = this;
      oVue.tasks.aTasks = [];
      KTUtil.setLoading();
      oVue.$store
        .dispatch(GET_TASK_FILTERED_CAMPAIGN_JOBBER, { iCampaignID, iJobberID })
        .then((tasks) => {
          oVue.$refs.TaskListDialog.setTasks(tasks);
          KTUtil.removeLoading();
        });
    },
    editJobber(aJobber) {
      let oVue = this;
      oVue.aJobber = aJobber;
      oVue.$nextTick(() => {
        oVue.$refs.JobberFormDialog.editJobber();
      });
    },
    addJobber() {
      let oVue = this;
      oVue.aJobber = {};
      oVue.$nextTick(() => {
        oVue.$refs.JobberFormDialog.addJobber();
      });
    },
    async cleanJobberImei(payload) {
      KTUtil.setLoading();

      try {
        const { result, message } = await this.$store.dispatch(
          CLEAN_JOBBER_IMEI,
          { id: payload.uniqueID }
        );

        let modalMessage = {
          title: "Error!",
          text: message,
          icon: "error",
        };

        if (result) {
          modalMessage = {
            title: "Enhorabuena!",
            text: "El IMEI ha sido limpiado.",
            icon: "success",
          };

          const jobberIndex = this.jobberList.findIndex(
            ({ uniqueID }) => uniqueID === payload.uniqueID
          );
          this.jobberList[jobberIndex].imei = null;
        }

        Swal.fire(modalMessage);
      } catch (error) {
        console.error(error);
        Swal.fire({
          title: "Error Inesperado!",
          text: "Hubo un error inesperado al momento de limpiar el IMEI",
          icon: "error",
        });
      }

      KTUtil.removeLoading();
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },
  watch: {
    aJobbers(value) {
      this.jobberList = value;
    },
    "dailyMessages.form.sFechaMensajeDatepicker"(val) {
      let oVue = this;
      if (val !== "" && val !== null) {
        oVue.dailyMessages.form.sFechaMensaje = moment(
          val,
          "YYYY-MM-DD"
        ).format("DD/MM/YYYY");
      }
    },
  },
};
</script>
