<template>
  <div data-app>
    <JobberList
      :aJobbers="aJobbers"
      :modulo="modulo"
      :tipo="'list'"
    ></JobberList>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import { GET_JOBBERS, GET_USUARIOS } from "@/core/services/store/jobber.module";
import JobberList from "../components/jobber/JobberList.vue";
export default {
  name: "jobbers",
  components: {
    JobberList,
  },
  props: {
    modulo: String,
  },
  data() {
    return {
      aJobbers: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `${this.modulo}s`, route: "jobbers" },
    ]);
  },
  created() {
    let oVue = this;
    oVue.getJobbers();
  },
  methods: {
    getJobbers() {
      KTUtil.setLoading();
      let oVue = this;
      let modulo = "";
      if (oVue.modulo == "Jobber") {
        modulo = GET_JOBBERS;
      } else {
        modulo = GET_USUARIOS;
      }
      oVue.$store.dispatch(modulo).then((aJobbers) => {
        oVue.aJobbers = aJobbers;
        KTUtil.removeLoading();
      });
    },
  },
};
</script>
