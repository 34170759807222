<template>
  <div>
    <v-dialog
      v-model="dialogShowJobber"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar flat dark color="primary" style="flex: none">
          <v-btn icon dark @click="dialogShowJobber = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-if="dialogType == 'add'">{{
            `Agregar ${modulo}`
          }}</v-toolbar-title>
          <v-toolbar-title v-else>{{
            `${modulo}: ${aJobber.nombre}`
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="pt-2 bg-default">
          <v-row class="font-size-120">
            <v-col cols="12">
              <div class="card card-custom">
                <div class="card-header">
                  <div class="card-title">
                    <h3 class="card-label">Datos Generales</h3>
                  </div>
                </div>
                <div class="card-body p-5 pb-7">
                  <form class="form">
                    <div class="pb-5">
                      <v-row>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="aJobber.rut"
                            label="Rut"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="aJobber.nombres"
                            label="Nombre"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="aJobber.apellido_paterno"
                            label="Apellido Paterno"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="aJobber.apellido_materno"
                            label="Apellido Materno"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="aJobber.correo"
                            label="Email"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="aJobber.telefono"
                            type="number"
                            label="Teléfono"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-menu
                            ref="datepicker"
                            v-model="fecha_nacimiento_dt"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="aJobber.fecha_nacimiento"
                                label="Fecha de Nacimiento"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="fecha_nacimiento_original"
                              @input="fecha_nacimiento_dt = false"
                              no-title
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <template v-if="modulo === 'Jobber'">
                          <v-col cols="12">
                            <Place
                              v-model="aJobber.direccion"
                              ref="Place"
                              @updateFields="updateFieldsFromPlace"
                            ></Place>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="aJobber.direccion_calle"
                              label="Calle"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="aJobber.direccion_numero"
                              label="Número"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-autocomplete
                              v-model="region"
                              :items="regiones"
                              @change="getProvincias"
                              item-key="id_region"
                              item-text="region"
                              return-object
                              label="Region"
                              autocomplete="new-password"
                              dense
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-autocomplete
                              v-model="provincia"
                              :items="provincias"
                              @change="getComunas"
                              item-key="id_provincia"
                              item-text="provincia"
                              return-object
                              label="Provincia"
                              autocomplete="new-password"
                              dense
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-autocomplete
                              v-model="comuna"
                              :items="comunas"
                              item-key="id_comuna"
                              item-text="comuna"
                              return-object
                              label="Comuna"
                              autocomplete="new-password"
                              dense
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-autocomplete
                              v-model="aJobber.id_estado"
                              :items="estados"
                              item-key="id"
                              item-value="id"
                              item-text="estado"
                              label="Estado"
                              dense
                              autocomplete="new-password"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-autocomplete
                              v-model="aJobber.id_grupo"
                              :items="grupos"
                              item-key="id"
                              item-value="id"
                              item-text="grupo"
                              label="Grupo"
                              dense
                              autocomplete="new-password"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-autocomplete
                              v-model="aJobber.id_nivel_estudio"
                              :items="niveles_estudio"
                              item-key="id"
                              item-value="id"
                              item-text="nivel_estudio"
                              label="Nivel Estudio"
                              dense
                              autocomplete="new-password"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-autocomplete
                              v-model="aJobber.id_profesion"
                              :items="profesiones"
                              item-key="id"
                              item-value="id"
                              item-text="profesion"
                              label="Profesion"
                              dense
                              autocomplete="new-password"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-textarea
                              v-model="aJobber.observaciones"
                              label="Observaciones"
                            >
                            </v-textarea>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-menu
                              ref="datepicker"
                              v-model="fecha_inscripcion_dt"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="aJobber.fecha_inscripcion"
                                  label="Inscripción"
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="fecha_inscripcion_original"
                                @input="fecha_inscripcion_dt = false"
                                no-title
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-menu
                              ref="datepicker"
                              v-model="fecha_antecedentes_dt"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="aJobber.fecha_antecedentes"
                                  label="Paso 2 / Antecedentes"
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="fecha_antecedentes_original"
                                @input="fecha_antecedentes_dt = false"
                                no-title
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-menu
                              ref="datepicker"
                              v-model="fecha_contrato_dt"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="aJobber.fecha_contrato"
                                  label="Firma Contrato"
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="fecha_contrato_original"
                                @input="fecha_contrato_dt = false"
                                no-title
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-menu
                              ref="datepicker"
                              v-model="vigente_hasta_dt"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="aJobber.vigente_hasta"
                                  label="Vigente Hasta"
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="vigente_hasta_original"
                                @input="vigente_hasta_dt = false"
                                no-title
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="4">
                            <v-autocomplete
                              v-model="aJobber.id_mandante"
                              :items="mandantes"
                              item-key="mandanteID"
                              item-value="mandanteID"
                              item-text="mandanteNombre"
                              label="Mandante"
                              autocomplete="new-password"
                              dense
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="4">
                            <v-autocomplete
                              v-model="aJobber.id_recurrencia_pago"
                              :items="recurrenciasPagos"
                              item-value="id"
                              item-text="recurrencia"
                              label="Recurrencia de Pago"
                              dense
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="4">
                            <v-checkbox
                              label="Multi Dispositivo"
                              v-model="aJobber.multiDispositivo"
                            ></v-checkbox>
                          </v-col>
                        </template>
                        <v-col cols="12" v-if="modulo !== 'Jobber'">
                          <v-autocomplete
                            v-model="aJobber.id_perfil"
                            :items="perfiles"
                            item-key="id"
                            item-value="id"
                            item-text="descripcion"
                            label="Perfil"
                            autocomplete="new-password"
                            dense
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" v-if="aJobber.id_perfil == 2">
                          <v-autocomplete
                            v-model="aJobber.id_mandante"
                            :items="mandantes"
                            item-key="mandanteID"
                            item-value="mandanteID"
                            item-text="mandanteNombre"
                            label="Mandantes"
                            dense
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            type="password"
                            v-model="password"
                            label="Contraseña"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <div
                        class="d-flex justify-content-between pt-10 float-right"
                      >
                        <v-btn
                          @click="saveJobber()"
                          color="primary"
                          dark
                          class="font-weight-bold text-uppercase px-9 py-4"
                        >
                          Guardar
                        </v-btn>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row class="font-size-120" v-if="aJobber.uniqueID">
            <v-col cols="6">
              <div class="card card-custom card-stretch mb-2">
                <div class="card-header">
                  <div class="card-title">
                    <h3 class="card-label">
                      Cobertura: {{ coberturas.length }}
                    </h3>
                  </div>
                </div>
                <div class="card-body p-5 pb-7">
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="cobertura"
                        :items="coberturasFilter"
                        item-key="id_comuna"
                        item-text="comuna"
                        return-object
                        label="Comuna"
                        autocomplete="new-password"
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="8">
                      <v-menu
                        ref="datepicker"
                        v-model="fecha_cobertura_dt"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fecha_cobertura"
                            label="Fecha"
                            v-bind="attrs"
                            v-on="on"
                            class="m-0 p-0"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fecha_cobertura_original"
                          @input="fecha_cobertura_dt = false"
                          no-title
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        @click="addComunaJobber()"
                        color="primary"
                        dark
                        class="font-weight-bold text-uppercase px-9 py-4 float-right"
                      >
                        Agregar
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="searchCobertura"
                        append-icon="search"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headersCobertura"
                        :items="coberturas"
                        :search="searchCobertura"
                        disable-pagination
                        hide-default-footer
                        class="table-striped elevation-1"
                        id="cobertura_table"
                      >
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon
                            small
                            class="mr-2"
                            @click="
                              checkDeleteJobberReq({
                                id: item.id,
                                type: 'Comuna',
                              })
                            "
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="card card-custom card-stretch mb-2">
                <div class="card-header">
                  <div class="card-title">
                    <h3 class="card-label">
                      Competencias: {{ competencias.length }}
                    </h3>
                  </div>
                </div>
                <div class="card-body p-5 pb-7">
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="competencia"
                        :items="competenciasFilter"
                        item-key="id"
                        item-text="competencia"
                        return-object
                        label="Competencia"
                        autocomplete="new-password"
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="8">
                      <v-menu
                        ref="datepicker"
                        v-model="fecha_competencia_dt"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fecha_competencia"
                            label="Fecha"
                            v-bind="attrs"
                            v-on="on"
                            class="m-0 p-0"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fecha_competencia_original"
                          @input="fecha_competencia_dt = false"
                          no-title
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        @click="addCompetenciaJobber()"
                        color="primary"
                        dark
                        class="font-weight-bold text-uppercase px-9 py-4 float-right"
                      >
                        Agregar
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="searchCompetencia"
                        append-icon="search"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headersCompetencia"
                        :items="competencias"
                        :search="searchCompetencia"
                        item-key="key"
                        disable-pagination
                        hide-default-footer
                        class="table-striped elevation-1"
                        id="competencia_table"
                      >
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon
                            small
                            class="mr-2"
                            @click="
                              checkDeleteJobberReq({
                                id: item.id,
                                type: 'Competencia',
                              })
                            "
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                        <template slot="body.append">
                          <tr
                            class="font-weight-bolder"
                            v-if="Object.keys(competencias).length > 0"
                          >
                            <td>Total</td>
                            <td class="text-center">
                              {{
                                competencias.reduce((s, a) => s + a.puntaje, 0)
                              }}
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row class="font-size-120" v-if="aJobber.uniqueID">
            <v-col cols="6">
              <div class="card card-custom card-stretch mb-2">
                <div class="card-header">
                  <div class="card-title">
                    <h3 class="card-label">
                      Capacitación: {{ capacitaciones.length }}
                    </h3>
                  </div>
                </div>
                <div class="card-body p-5 pb-7">
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="capacitacion"
                        :items="capacitacionesFilter"
                        item-key="id"
                        item-text="capacitacion"
                        return-object
                        label="Capacitación"
                        autocomplete="new-password"
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="8">
                      <v-menu
                        ref="datepicker"
                        v-model="fecha_capacitacion_dt"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fecha_capacitacion"
                            label="Fecha"
                            v-bind="attrs"
                            v-on="on"
                            class="m-0 p-0"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fecha_capacitacion_original"
                          @input="fecha_capacitacion_dt = false"
                          no-title
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        @click="addCapacitacionJobber()"
                        color="primary"
                        dark
                        class="font-weight-bold text-uppercase px-9 py-4 float-right"
                      >
                        Agregar
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="searchCapacitacion"
                        append-icon="search"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headersCapacitacion"
                        :items="capacitaciones"
                        :search="searchCapacitacion"
                        item-key="key"
                        disable-pagination
                        hide-default-footer
                        class="table-striped elevation-1"
                        id="capacitacion_table"
                      >
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon
                            small
                            class="mr-2"
                            @click="
                              checkDeleteJobberReq({
                                id: item.id,
                                type: 'Capacitacion',
                              })
                            "
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                        <template slot="body.append">
                          <tr
                            class="font-weight-bolder"
                            v-if="Object.keys(capacitaciones).length > 0"
                          >
                            <td>Total</td>
                            <td class="text-center">
                              {{
                                capacitaciones.reduce(
                                  (s, a) => s + a.puntaje,
                                  0
                                )
                              }}
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="card card-custom card-stretch">
                <div class="card-header">
                  <div class="card-title">
                    <h3 class="card-label">
                      Antecedentes: {{ antecedentes.length }}
                    </h3>
                  </div>
                </div>
                <div class="card-body p-5 pb-7">
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="antecedente"
                        :items="antecedentesFilter"
                        item-key="id"
                        item-text="antecedente"
                        return-object
                        label="Antecedente"
                        autocomplete="new-password"
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="8">
                      <v-menu
                        ref="datepicker"
                        v-model="fecha_antecedente_dt"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fecha_antecedente"
                            label="Fecha"
                            v-bind="attrs"
                            v-on="on"
                            class="m-0 p-0"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fecha_antecedente_original"
                          @input="fecha_antecedente_dt = false"
                          no-title
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        @click="addAntecedenteJobber()"
                        color="primary"
                        dark
                        class="font-weight-bold text-uppercase px-9 py-4 float-right"
                      >
                        Agregar
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="searchAntecendente"
                        append-icon="search"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headersAntecedente"
                        :items="antecedentes"
                        :search="searchAntecendente"
                        item-key="key"
                        disable-pagination
                        hide-default-footer
                        class="table-striped elevation-1"
                        id="antecedente_table"
                      >
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon
                            small
                            class="mr-2"
                            @click="
                              checkDeleteJobberReq({
                                id: item.id,
                                type: 'Antecedente',
                              })
                            "
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                        <template slot="body.append">
                          <tr
                            class="font-weight-bolder"
                            v-if="Object.keys(antecedentes).length > 0"
                          >
                            <td>Total</td>
                            <td class="text-center">
                              {{
                                antecedentes.reduce((s, a) => s + a.puntaje, 0)
                              }}
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row class="font-size-120" v-if="aJobber.uniqueID">
            <v-col cols="6">
              <div class="card card-custom card-stretch mb-2">
                <div class="card-header">
                  <div class="card-title">
                    <h3 class="card-label">Cuentas: {{ cuentas.length }}</h3>
                  </div>
                </div>
                <div class="card-body p-5 pb-7">
                  <v-row>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="banco"
                        :items="bancos"
                        item-key="id"
                        item-text="banco"
                        return-object
                        label="Banco"
                        autocomplete="new-password"
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="tipo_cuenta"
                        :items="tipo_cuentas"
                        item-key="id"
                        item-text="tipo_cuenta"
                        return-object
                        label="Tipo de Cuenta"
                        autocomplete="new-password"
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        v-model="numero_cuenta"
                        label="Numero de Cuenta"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        @click="addCuentaJobber()"
                        color="primary"
                        dark
                        class="font-weight-bold text-uppercase px-9 py-4 float-right"
                      >
                        Agregar
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="searchCuenta"
                        append-icon="search"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headersCuenta"
                        :items="cuentas"
                        :search="searchCuenta"
                        item-key="key"
                        disable-pagination
                        hide-default-footer
                        class="table-striped elevation-1"
                        id="cuenta_table"
                      >
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon
                            small
                            class="mr-2"
                            @click="
                              checkDeleteJobberReq({
                                id: item.id,
                                type: 'Cuenta',
                              })
                            "
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { _ } from "vue-underscore";
import { validate } from "rut.js";
import KTUtil from "@/assets/js/components/util";
import moment from "moment";
import Swal from "sweetalert2";
import {
  GET_USUARIO_ESTADOS,
  GET_USUARIO_GRUPOS,
  GET_USUARIO_NIVELES_ESTUDIO,
  GET_USUARIO_PROFESIONES,
  GET_USUARIO_BANCOS,
  GET_USUARIO_TIPO_CUENTAS,
  GET_USUARIO_CUENTAS,
  GET_ALL_JOBBER_DATA,
  GET_COMPETENCIAS,
  GET_CAPACITACIONES,
  GET_ANTECEDENTES,
  GET_RECURRENCIAS_PAGOS,
  SAVE_JOBBER_DATA,
  NEW_COMUNA_JOBBER,
  NEW_COMPETENCIA_JOBBER,
  NEW_CAPACITACION_JOBBER,
  NEW_ANTECEDENTE_JOBBER,
  NEW_CUENTA_JOBBER,
  DELETE_DETALLE_JOBBER,
} from "@/core/services/store/jobber.module";
import {
  GET_REGIONES,
  GET_PERFILES,
} from "@/core/services/store/global.module";
import { GET_MANDANTES } from "@/core/services/store/mandante.module";
import Place from "../common/Places.vue";
export default {
  name: "JobberFormDialog",
  props: {
    aJobber: Object,
    modulo: String,
  },
  components: {
    Place,
  },
  data() {
    return {
      password: null,
      dialogShowJobber: false,
      dialogType: "",
      fecha_nacimiento_dt: false,
      fecha_nacimiento_original: null,
      estados: [],
      grupos: [],
      niveles_estudio: [],
      profesiones: [],
      vigente_hasta_dt: false,
      vigente_hasta_original: null,
      region: null,
      regiones: [],
      provincia: null,
      provincias: [],
      comuna: null,
      comunas: [],
      headersCobertura: [
        {
          text: "Comuna",
          value: "comuna",
        },
        {
          text: "Región",
          value: "region",
        },
        {
          text: "Fecha",
          value: "fecha",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      coberturas: [],
      searchCobertura: "",
      headersCompetencia: [
        {
          text: "Competencia",
          value: "competencia",
        },
        {
          text: "Puntaje",
          value: "puntaje",
          align: "center",
        },
        {
          text: "Fecha",
          value: "fecha",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      competencias: [],
      searchCompetencia: "",
      headersCapacitacion: [
        {
          text: "Capacitación",
          value: "capacitacion",
        },
        {
          text: "Puntaje",
          value: "puntaje",
          align: "center",
        },
        {
          text: "Fecha",
          value: "fecha",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      capacitaciones: [],
      searchCapacitacion: "",
      headersAntecedente: [
        {
          text: "Antecedente",
          value: "antecedente",
        },
        {
          text: "Puntaje",
          value: "puntaje",
          align: "center",
        },
        {
          text: "Fecha",
          value: "fecha",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      antecedentes: [],
      searchAntecendente: "",
      competenciasFilter: [],
      competencia: null,
      capacitacionesFilter: [],
      capacitacion: null,
      antecedentesFilter: [],
      antecedente: null,
      coberturasFilter: [],
      cobertura: null,
      fecha_inscripcion_dt: false,
      fecha_inscripcion_original: null,
      fecha_antecedentes_dt: false,
      fecha_antecedentes_original: null,
      fecha_contrato_dt: false,
      fecha_contrato_original: null,
      fecha_cobertura_dt: false,
      fecha_cobertura: null,
      fecha_cobertura_original: null,
      fecha_competencia_dt: false,
      fecha_competencia: null,
      fecha_competencia_original: null,
      fecha_capacitacion_dt: false,
      fecha_capacitacion: null,
      fecha_capacitacion_original: null,
      fecha_antecedente_dt: false,
      fecha_antecedente: null,
      fecha_antecedente_original: null,
      headersCuenta: [
        {
          text: "Banco",
          value: "banco",
        },
        {
          text: "Tipo de Cuenta",
          value: "tipo_cuenta",
        },
        {
          text: "Numero de Cuenta",
          value: "numero_cuenta",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      cuentas: [],
      searchCuenta: "",
      bancos: [],
      banco: null,
      tipo_cuentas: [],
      tipo_cuenta: null,
      numero_cuenta: null,
      mandantes: [],
      perfiles: [],
      recurrenciasPagos: [],
    };
  },
  created() {
    let oVue = this;
    oVue.getUsuarioEstados();
    oVue.getUsuarioGrupos();
    oVue.getUsuarioNivelesEstudio();
    oVue.getUsuarioProfesiones();
    oVue.getRegiones();
    oVue.getCompetencias();
    oVue.getCapacitaciones();
    oVue.getAntecedentes();
    oVue.getUsuarioBancos();
    oVue.getUsuarioTiposCuenta();
    oVue.getMandantes();
    oVue.getPerfiles();
    oVue.getRecurrenciasPago();
  },
  methods: {
    addJobber() {
      let oVue = this;
      if (oVue.modulo === "Jobber") oVue.aJobber.id_perfil = 3;
      oVue.dialogType = "add";
      oVue.dialogShowJobber = true;
    },
    async editJobber() {
      let oVue = this;
      let aJobber = oVue.aJobber;
      let oDT;
      if (aJobber.fecha_nacimiento) {
        oDT = moment(aJobber.fecha_nacimiento, "YYYY-MM-DD");
        oVue.fecha_nacimiento_original = oDT.format("YYYY-MM-DD");
        aJobber.fecha_nacimiento = oDT.format("DD-MM-YYYY");
      } else {
        aJobber.fecha_nacimiento = "";
        oVue.fecha_nacimiento_original = "";
      }
      if (aJobber.vigente_hasta) {
        oDT = moment(aJobber.vigente_hasta, "YYYY-MM-DD");
        oVue.vigente_hasta_original = oDT.format("YYYY-MM-DD");
        aJobber.vigente_hasta = oDT.format("DD-MM-YYYY");
      } else {
        aJobber.vigente_hasta = "";
        oVue.vigente_hasta_original = "";
      }
      if (aJobber.fecha_inscripcion) {
        oDT = moment(aJobber.fecha_inscripcion, "YYYY-MM-DD");
        oVue.fecha_inscripcion_original = oDT.format("YYYY-MM-DD");
        aJobber.fecha_inscripcion = oDT.format("DD-MM-YYYY");
      } else {
        aJobber.fecha_inscripcion = "";
        oVue.fecha_inscripcion_original = "";
      }
      if (aJobber.fecha_antecedentes) {
        oDT = moment(aJobber.fecha_antecedentes, "YYYY-MM-DD");
        oVue.fecha_antecedentes_original = oDT.format("YYYY-MM-DD");
        aJobber.fecha_antecedentes = oDT.format("DD-MM-YYYY");
      } else {
        aJobber.fecha_antecedentes = "";
        oVue.fecha_antecedentes_original = "";
      }
      if (aJobber.fecha_contrato) {
        oDT = moment(aJobber.fecha_contrato, "YYYY-MM-DD");
        oVue.fecha_contrato_original = oDT.format("YYYY-MM-DD");
        aJobber.fecha_contrato = oDT.format("DD-MM-YYYY");
      } else {
        aJobber.fecha_contrato = "";
        oVue.fecha_contrato_original = "";
      }

      oVue.aJobber = aJobber;
      oVue.dialogType = "edit";
      oVue.dialogShowJobber = true;
      oVue.setComuna();
      oVue.setDireccion();

      await oVue.getJobber();
    },
    async getJobber() {
      let oVue = this;
      const aAllJobberDataResponse = await oVue.getAllJobberData(
        oVue.aJobber.uniqueID
      );
      if (aAllJobberDataResponse.result) {
        const aCoberturas =
          typeof aAllJobberDataResponse.data.coberturas !== "object"
            ? JSON.parse(aAllJobberDataResponse.data.coberturas)
            : aAllJobberDataResponse.data.coberturas;
        const aCompetencias =
          typeof aAllJobberDataResponse.data.competencias !== "object"
            ? JSON.parse(aAllJobberDataResponse.data.competencias)
            : aAllJobberDataResponse.data.competencias;
        const aCapacitaciones =
          typeof aAllJobberDataResponse.data.capacitaciones !== "object"
            ? JSON.parse(aAllJobberDataResponse.data.capacitaciones)
            : aAllJobberDataResponse.data.capacitaciones;
        const aAntecedentes =
          typeof aAllJobberDataResponse.data.antecedentes !== "object"
            ? JSON.parse(aAllJobberDataResponse.data.antecedentes)
            : aAllJobberDataResponse.data.antecedentes;

        oVue.coberturas = aCoberturas;
        oVue.competencias = aCompetencias;
        oVue.capacitaciones = aCapacitaciones;
        oVue.antecedentes = aAntecedentes;
        oVue.getUsuarioCuentas();
      }
    },
    async getAllJobberData(jobber) {
      KTUtil.setLoading();
      let oVue = this;
      const aAllJobberDataResponse = await oVue.$store.dispatch(
        GET_ALL_JOBBER_DATA,
        jobber
      );
      KTUtil.removeLoading();
      return aAllJobberDataResponse;
    },
    getRegiones() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_REGIONES).then((coberturas) => {
        var aRegiones = _.chain(coberturas)
          .groupBy("id_region")
          .map(function (value, index) {
            return {
              id_region: index,
              region: value[0].region,
              provincias: value,
            };
          })
          .value();
        aRegiones.unshift({ id_region: "", region: "" });
        oVue.regiones = aRegiones;
        oVue.coberturasFilter = coberturas;
        KTUtil.removeLoading();
      });
    },
    getProvincias() {
      let oVue = this;
      if (oVue.aJobber.id_region != "") {
        let aProvincias = _.chain(oVue.region.provincias)
          .groupBy("id_provincia")
          .map(function (value, index) {
            return {
              id_provincia: index,
              provincia: value[0].provincia,
              comunas: value,
            };
          })
          .value();
        aProvincias.unshift({ id_provincia: null, provincia: "" });
        oVue.provincias = aProvincias;
      } else {
        oVue.provincias = [];
        oVue.comunas = [];
        oVue.aJobber.id_provincia = null;
        oVue.aJobber.id_comuna = null;
      }
    },
    getComunas() {
      let oVue = this;
      if (oVue.aJobber.id_provincia != "") {
        let aComunas = _.chain(oVue.provincia.comunas)
          .groupBy("id_comuna")
          .map(function (value, index) {
            return {
              id_comuna: index,
              comuna: value[0].comuna,
            };
          })
          .value();
        aComunas.unshift({ id_comuna: null, comuna: "" });
        oVue.comunas = aComunas;
      } else {
        oVue.comunas = [];
        oVue.aJobber.id_comuna = null;
      }
    },
    getCompetencias() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_COMPETENCIAS).then((competencias) => {
        oVue.competenciasFilter = competencias;
        KTUtil.removeLoading();
      });
    },
    getCapacitaciones() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_CAPACITACIONES).then((capacitaciones) => {
        oVue.capacitacionesFilter = capacitaciones;
        KTUtil.removeLoading();
      });
    },
    getAntecedentes() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_ANTECEDENTES).then((antecedentes) => {
        oVue.antecedentesFilter = antecedentes;
        KTUtil.removeLoading();
      });
    },
    getUsuarioEstados() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_USUARIO_ESTADOS).then((estados) => {
        oVue.estados = estados;
        KTUtil.removeLoading();
      });
    },
    getUsuarioGrupos() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_USUARIO_GRUPOS).then((grupos) => {
        oVue.grupos = grupos;
        KTUtil.removeLoading();
      });
    },
    getUsuarioNivelesEstudio() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store
        .dispatch(GET_USUARIO_NIVELES_ESTUDIO)
        .then((niveles_estudio) => {
          oVue.niveles_estudio = niveles_estudio;
          KTUtil.removeLoading();
        });
    },
    getUsuarioProfesiones() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_USUARIO_PROFESIONES).then((profesiones) => {
        oVue.profesiones = profesiones;
        KTUtil.removeLoading();
      });
    },
    getUsuarioBancos() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_USUARIO_BANCOS).then((bancos) => {
        oVue.bancos = bancos;
        KTUtil.removeLoading();
      });
    },
    getUsuarioTiposCuenta() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_USUARIO_TIPO_CUENTAS).then((tipo_cuentas) => {
        oVue.tipo_cuentas = tipo_cuentas;
        KTUtil.removeLoading();
      });
    },
    getUsuarioCuentas() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store
        .dispatch(GET_USUARIO_CUENTAS, oVue.aJobber.uniqueID)
        .then((cuentas) => {
          oVue.cuentas = cuentas;
          KTUtil.removeLoading();
        });
    },
    getMandantes() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.mandantes = [];
      oVue.$store.dispatch(GET_MANDANTES).then((mandantes) => {
        oVue.mandantes = mandantes;
        KTUtil.removeLoading();
      });
    },
    getPerfiles() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.perfiles = [];
      oVue.$store.dispatch(GET_PERFILES).then((perfiles) => {
        oVue.perfiles = perfiles;
        KTUtil.removeLoading();
      });
    },
    getRecurrenciasPago() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_RECURRENCIAS_PAGOS).then((response) => {
        oVue.recurrenciasPagos = response;
        KTUtil.removeLoading();
      });
    },
    capitalize(str) {
      if (!str) return str;
      const separator = " ";
      const words = str.split(separator);
      let result = "";
      for (var i = 0; i < words.length; i++) {
        const word = words[i];
        const capitalizedWord =
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        result += capitalizedWord;
      }
      return result;
    },
    setDireccion() {
      let oVue = this;
      const aJobber = oVue.aJobber;

      const direccion =
        [aJobber.direccion_calle, aJobber.direccion_numero].join(" ") +
        ", " +
        [oVue.capitalize(aJobber.comuna), "Chile"].join(", ");

      const oDireccion = [
        {
          description: direccion,
          place_id: 1,
        },
      ];

      setTimeout(() => {
        oVue.$refs.Place.setDireccion(oDireccion);
      }, 500);
    },
    formatDate(date) {
      if (!date) return null;

      return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    },
    validEmail: function (email) {
      //eslint-disable-next-line
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validateForm() {
      let oVue = this;
      const aJobber = oVue.aJobber;
      if (!validate(aJobber.rut) || !aJobber.rut.includes("-")) {
        Swal.fire({
          title: `Error al guardar el ${oVue.modulo}`,
          text: "El RUT es invalido",
          icon: "error",
        });
        return false;
      }
      if (!oVue.validEmail(aJobber.correo)) {
        Swal.fire({
          title: `Error al guardar el ${oVue.modulo}`,
          text: "El Correo es invalido",
          icon: "error",
        });
        return false;
      }
      if (
        aJobber.fecha_nacimiento &&
        !moment(aJobber.fecha_nacimiento, "DD-MM-YYYY", true).isValid()
      ) {
        Swal.fire({
          title: `Error al guardar el ${oVue.modulo}`,
          text: "La fecha de nacimiento es invalida",
          icon: "error",
        });
        return false;
      }
      if (oVue.modulo == "Jobber") {
        if (
          aJobber.vigente_hasta &&
          !moment(aJobber.vigente_hasta, "DD-MM-YYYY", true).isValid()
        ) {
          Swal.fire({
            title: `Error al guardar el ${oVue.modulo}`,
            text: "El campo de Vigente Hasta es invalido",
            icon: "error",
          });
          return false;
        }
        if (
          aJobber.fecha_inscripcion &&
          !moment(aJobber.fecha_inscripcion, "DD-MM-YYYY", true).isValid()
        ) {
          Swal.fire({
            title: `Error al guardar el ${oVue.modulo}`,
            text: "El campo de Inscripción es invalido",
            icon: "error",
          });
          return false;
        }
        if (
          aJobber.fecha_antecedentes &&
          !moment(aJobber.fecha_antecedentes, "DD-MM-YYYY", true).isValid()
        ) {
          Swal.fire({
            title: `Error al guardar el ${oVue.modulo}`,
            text: "El campo de Paso 2 es invalido",
            icon: "error",
          });
          return false;
        }
        if (
          aJobber.fecha_contrato &&
          !moment(aJobber.fecha_contrato, "DD-MM-YYYY", true).isValid()
        ) {
          Swal.fire({
            title: `Error al guardar el ${oVue.modulo}`,
            text: "El campo de Firma Contrato es invalido",
            icon: "error",
          });
          return false;
        }
      }
      if (!aJobber.id_perfil) {
        Swal.fire({
          title: `Error al guardar el ${oVue.modulo}`,
          text: "Debe seleccionar un perfil",
          icon: "error",
        });
        return false;
      }
      if (aJobber.id_perfil === 2 && !aJobber.id_mandante) {
        Swal.fire({
          title: `Error al guardar el ${oVue.modulo}`,
          text: "Debe seleccionar un mandante",
          icon: "error",
        });
        return false;
      }
      if (!aJobber.uniqueID && !this.password) {
        Swal.fire({
          title: `Error al guardar el ${oVue.modulo}`,
          text: "Debe ingresar una contraseña",
          icon: "error",
        });
        return false;
      }
      return true;
    },
    saveJobber() {
      let oVue = this;
      const aJobber = oVue.aJobber;
      if (!oVue.validateForm()) return;
      KTUtil.setLoading();
      const rut_dv = aJobber.rut.split("-");
      const rut = rut_dv[0];
      const dig_rut = rut_dv[1];

      let oData = {
        rut,
        dig_rut,
        nombres: aJobber.nombres ? aJobber.nombres : "",
        apellido_paterno: aJobber.apellido_paterno
          ? aJobber.apellido_paterno
          : "",
        apellido_materno: aJobber.apellido_materno
          ? aJobber.apellido_materno
          : "",
        correo: aJobber.correo,
        telefono: aJobber.telefono ? aJobber.telefono : "",
        fecha_nacimiento: aJobber.fecha_nacimiento
          ? moment(aJobber.fecha_nacimiento, "DD-MM-YYYY").format("YYYY-MM-DD")
          : null,
        direccion_calle: aJobber.direccion_calle
          ? aJobber.direccion_calle
          : null,
        direccion_numero: aJobber.direccion_numero
          ? aJobber.direccion_numero
          : null,
        id_estado: aJobber.id_estado ? aJobber.id_estado : 1,
        id_grupo: aJobber.id_grupo ? aJobber.id_grupo : null,
        id_nivel_estudio: aJobber.id_nivel_estudio
          ? aJobber.id_nivel_estudio
          : null,
        id_profesion: aJobber.id_profesion ? aJobber.id_profesion : null,
        vigente_hasta: aJobber.vigente_hasta
          ? moment(aJobber.vigente_hasta, "DD-MM-YYYY").format("YYYY-MM-DD")
          : null,
        observaciones: aJobber.observaciones ? aJobber.observaciones : null,
        id_comuna: oVue.comuna ? oVue.comuna.id_comuna : null,
        latitud: aJobber.latitud ? aJobber.latitud : null,
        longitud: aJobber.longitud ? aJobber.longitud : null,
        fecha_inscripcion: aJobber.fecha_inscripcion
          ? moment(aJobber.fecha_inscripcion, "DD-MM-YYYY").format("YYYY-MM-DD")
          : null,
        fecha_antecedentes: aJobber.fecha_antecedentes
          ? moment(aJobber.fecha_antecedentes, "DD-MM-YYYY").format(
              "YYYY-MM-DD"
            )
          : null,
        fecha_contrato: aJobber.fecha_contrato
          ? moment(aJobber.fecha_contrato, "DD-MM-YYYY").format("YYYY-MM-DD")
          : null,
        clave: this.password || null,
        id_mandante: aJobber.id_mandante ? aJobber.id_mandante : null,
        id_perfil: aJobber.id_perfil ? aJobber.id_perfil : null,
        contrato: aJobber.contrato ? aJobber.contrato : null,
        id_recurrencia_pago: aJobber.id_recurrencia_pago
          ? aJobber.id_recurrencia_pago
          : 1,
        foto_perfil: aJobber.foto_perfil ? aJobber.foto_perfil : null,
        multiDispositivo: aJobber.multiDispositivo
          ? aJobber.multiDispositivo
          : false,
      };
      if (aJobber.uniqueID) {
        oData.id = Number(aJobber.uniqueID);
      }
      oVue.saveJobberData(oData).then((aSaveJobberResponse) => {
        KTUtil.removeLoading();
        if (aSaveJobberResponse.result) {
          Swal.fire({
            title: "¡Enhorabuena!",
            text: `Los datos del ${oVue.modulo} fueron guardados exitosamente`,
            icon: "success",
          });
          oVue.dialogShowJobber = false;
        } else {
          Swal.fire({
            title: `Error al guardar el ${oVue.modulo}`,
            text: aSaveJobberResponse.message,
            icon: "error",
          });
        }
      });
    },
    async saveJobberData(aData) {
      let oVue = this;
      const aSaveJobberResponse = await oVue.$store.dispatch(
        SAVE_JOBBER_DATA,
        aData
      );
      return aSaveJobberResponse;
    },
    addComunaJobber() {
      let oVue = this;
      if (!oVue.cobertura) {
        Swal.fire({
          title: "Error al guardar la comuna",
          text: "Debe seleccionar una comuna",
          icon: "error",
        });
        return;
      }
      if (
        oVue.fecha_cobertura &&
        !moment(oVue.fecha_cobertura, "DD-MM-YYYY", true).isValid()
      ) {
        Swal.fire({
          title: `Error al guardar el ${oVue.modulo}`,
          text: "El campo de Fecha es invalido",
          icon: "error",
        });
        return false;
      }
      KTUtil.setLoading();
      const aJobber = oVue.aJobber;
      const fecha = oVue.fecha_cobertura
        ? moment(oVue.fecha_cobertura, "DD-MM-YYYY").format("YYYY-MM-DD")
        : null;
      let oData = {
        id: aJobber.uniqueID,
        comuna: oVue.cobertura.id_comuna,
        fecha,
      };
      oVue.newComunaJobber(oData).then((aNewComunaResponse) => {
        KTUtil.removeLoading();
        if (aNewComunaResponse.result) {
          Swal.fire({
            title: "¡Enhorabuena!",
            text: "La comuna fue guardada exitosamente",
            icon: "success",
          });
          const oComuna = {
            id: aNewComunaResponse.id,
            comuna: oVue.cobertura.comuna,
            region: oVue.cobertura.region,
            fecha: oVue.fecha_cobertura,
          };
          oVue.coberturas.push(oComuna);
          oVue.cobertura = null;
        } else {
          Swal.fire({
            title: "Error al guardar la comuna",
            text: aNewComunaResponse.message,
            icon: "error",
          });
        }
      });
    },
    async newComunaJobber(aData) {
      let oVue = this;
      const aNewComunaResponse = await oVue.$store.dispatch(
        NEW_COMUNA_JOBBER,
        aData
      );
      return aNewComunaResponse;
    },
    addCompetenciaJobber() {
      let oVue = this;
      if (!oVue.competencia) {
        Swal.fire({
          title: "Error al guardar la competencia",
          text: "Debe seleccionar una competencia",
          icon: "error",
        });
        return;
      }
      if (
        oVue.fecha_competencia &&
        !moment(oVue.fecha_competencia, "DD-MM-YYYY", true).isValid()
      ) {
        Swal.fire({
          title: `Error al guardar el ${oVue.modulo}`,
          text: "El campo de Fecha es invalido",
          icon: "error",
        });
        return false;
      }
      KTUtil.setLoading();
      const aJobber = oVue.aJobber;
      const fecha = oVue.fecha_competencia
        ? moment(oVue.fecha_competencia, "DD-MM-YYYY").format("YYYY-MM-DD")
        : null;
      let oData = {
        id: aJobber.uniqueID,
        competencia: oVue.competencia.id,
        fecha,
      };
      oVue.newCompetenciaJobber(oData).then((aNewCompetenciaResponse) => {
        KTUtil.removeLoading();
        if (aNewCompetenciaResponse.result) {
          Swal.fire({
            title: "¡Enhorabuena!",
            text: "La competencia fue guardada exitosamente",
            icon: "success",
          });
          const oCompetencia = {
            id: aNewCompetenciaResponse.id,
            competencia: oVue.competencia.competencia,
            puntaje: oVue.competencia.puntaje,
            fecha: oVue.fecha_competencia,
          };
          oVue.competencias.push(oCompetencia);
          oVue.competencia = null;
        } else {
          Swal.fire({
            title: "Error al guardar la competencia",
            text: aNewCompetenciaResponse.message,
            icon: "error",
          });
        }
      });
    },
    async newCompetenciaJobber(aData) {
      let oVue = this;
      const aNewCompetenciaResponse = await oVue.$store.dispatch(
        NEW_COMPETENCIA_JOBBER,
        aData
      );
      return aNewCompetenciaResponse;
    },
    addCapacitacionJobber() {
      let oVue = this;
      if (!oVue.capacitacion) {
        Swal.fire({
          title: "Error al guardar la capacitacion",
          text: "Debe seleccionar una capacitacion",
          icon: "error",
        });
        return;
      }
      if (
        oVue.fecha_capacitacion &&
        !moment(oVue.fecha_capacitacion, "DD-MM-YYYY", true).isValid()
      ) {
        Swal.fire({
          title: `Error al guardar el ${oVue.modulo}`,
          text: "El campo de Fecha es invalido",
          icon: "error",
        });
        return false;
      }
      KTUtil.setLoading();
      const aJobber = oVue.aJobber;
      const fecha = oVue.fecha_capacitacion
        ? moment(oVue.fecha_capacitacion, "DD-MM-YYYY").format("YYYY-MM-DD")
        : null;
      let oData = {
        id: aJobber.uniqueID,
        capacitacion: oVue.capacitacion.id,
        fecha,
      };
      oVue.newCapacitacionJobber(oData).then((aNewCapacitacionResponse) => {
        KTUtil.removeLoading();
        if (aNewCapacitacionResponse.result) {
          Swal.fire({
            title: "¡Enhorabuena!",
            text: "La capacitación fue guardada exitosamente",
            icon: "success",
          });
          const oCapacitacion = {
            id: aNewCapacitacionResponse.id,
            capacitacion: oVue.capacitacion.capacitacion,
            puntaje: oVue.capacitacion.puntaje,
            fecha: oVue.fecha_capacitacion,
          };
          oVue.capacitaciones.push(oCapacitacion);
          oVue.capacitacion = null;
        } else {
          Swal.fire({
            title: "Error al guardar la capacitación",
            text: aNewCapacitacionResponse.message,
            icon: "error",
          });
        }
      });
    },
    async newCapacitacionJobber(aData) {
      let oVue = this;
      const aNewCapacitacionResponse = await oVue.$store.dispatch(
        NEW_CAPACITACION_JOBBER,
        aData
      );
      return aNewCapacitacionResponse;
    },
    addAntecedenteJobber() {
      let oVue = this;
      if (!oVue.antecedente) {
        Swal.fire({
          title: "Error al guardar el antecedente",
          text: "Debe seleccionar un antecedente",
          icon: "error",
        });
        return;
      }
      if (
        oVue.fecha_antecedente &&
        !moment(oVue.fecha_antecedente, "DD-MM-YYYY", true).isValid()
      ) {
        Swal.fire({
          title: `Error al guardar el ${oVue.modulo}`,
          text: "El campo de Fecha es invalido",
          icon: "error",
        });
        return false;
      }
      KTUtil.setLoading();
      const aJobber = oVue.aJobber;
      const fecha = oVue.fecha_antecedente
        ? moment(oVue.fecha_antecedente, "DD-MM-YYYY").format("YYYY-MM-DD")
        : null;
      let oData = {
        id: aJobber.uniqueID,
        antecedente: oVue.antecedente.id,
        fecha,
      };
      oVue.newAntecedenteJobber(oData).then((aNewAntecedenteResponse) => {
        KTUtil.removeLoading();
        if (aNewAntecedenteResponse.result) {
          Swal.fire({
            title: "¡Enhorabuena!",
            text: "El antecedente fue guardado exitosamente",
            icon: "success",
          });
          const oAntecedente = {
            id: aNewAntecedenteResponse.id,
            antecedente: oVue.antecedente.antecedente,
            puntaje: oVue.antecedente.puntaje,
            fecha: oVue.fecha_antecedente,
          };
          oVue.antecedentes.push(oAntecedente);
          oVue.antecedente = null;
        } else {
          Swal.fire({
            title: "Error al guardar el antecedente",
            text: aNewAntecedenteResponse.message,
            icon: "error",
          });
        }
      });
    },
    async newAntecedenteJobber(aData) {
      let oVue = this;
      const aNewAntecedenteResponse = await oVue.$store.dispatch(
        NEW_ANTECEDENTE_JOBBER,
        aData
      );
      return aNewAntecedenteResponse;
    },
    addCuentaJobber() {
      let oVue = this;
      if (!oVue.banco) {
        Swal.fire({
          title: "Error al guardar la cuenta",
          text: "Debe seleccionar un banco",
          icon: "error",
        });
        return;
      }
      if (!oVue.tipo_cuenta) {
        Swal.fire({
          title: "Error al guardar la cuenta",
          text: "Debe seleccionar un tipo de cuenta",
          icon: "error",
        });
        return;
      }
      if (!oVue.numero_cuenta) {
        Swal.fire({
          title: "Error al guardar la cuenta",
          text: "Debe ingresar un numero de cuenta",
          icon: "error",
        });
        return;
      }
      KTUtil.setLoading();
      const aJobber = oVue.aJobber;
      let oData = {
        jobber: aJobber.uniqueID,
        banco: oVue.banco.id,
        tipo_cuenta: oVue.tipo_cuenta.id,
        numero_cuenta: oVue.numero_cuenta,
      };
      oVue.newCuentaJobber(oData).then((aNewCuentaResponse) => {
        KTUtil.removeLoading();
        if (aNewCuentaResponse.result) {
          Swal.fire({
            title: "¡Enhorabuena!",
            text: "La cuenta fue guardada exitosamente",
            icon: "success",
          });
          oVue.getUsuarioCuentas();
          oVue.banco = null;
          oVue.tipo_cuenta = null;
          oVue.numero_cuenta = null;
        } else {
          Swal.fire({
            title: "Error al guardar la cuenta",
            text: aNewCuentaResponse.message,
            icon: "error",
          });
        }
      });
    },
    async newCuentaJobber(aData) {
      let oVue = this;
      const aNewCuentaResponse = await oVue.$store.dispatch(
        NEW_CUENTA_JOBBER,
        aData
      );
      return aNewCuentaResponse;
    },
    updateFieldsFromPlace(oData) {
      let oVue = this;
      const oAddress_components = oData.address_components;

      const oCalle = _.find(oAddress_components, (oCalle) =>
        oCalle.types.includes("route")
      );
      const sCalle = oCalle ? oCalle.long_name : "";

      const oNumeroCalle = _.find(oAddress_components, (oNumeroCalle) =>
        oNumeroCalle.types.includes("street_number")
      );
      const sNumeroCalle = oNumeroCalle ? oNumeroCalle.long_name : "";

      const oComuna = _.find(oAddress_components, (oComuna) =>
        oComuna.types.includes("locality")
      );
      const sComuna = oComuna ? oComuna.long_name : "";
      const oCobertura = _.findWhere(oVue.coberturasFilter, {
        comuna: sComuna.toUpperCase(),
      });

      oVue.aJobber.direccion_calle = sCalle;
      oVue.aJobber.direccion_numero = sNumeroCalle;
      oVue.aJobber.id_region = oCobertura.id_region;
      oVue.aJobber.id_provincia = oCobertura.id_provincia;
      oVue.aJobber.id_comuna = oCobertura.id_comuna;
      oVue.setComuna();

      const oLocation = oData.geometry.location;

      oVue.aJobber.latitud = oLocation.lat();
      oVue.aJobber.longitud = oLocation.lng();
    },
    setComuna() {
      let oVue = this;
      const aJobber = oVue.aJobber;
      if (aJobber.id_comuna) {
        oVue.region = oVue.regiones.find(
          (x) => x.id_region == aJobber.id_region
        );
        oVue.getProvincias();
        oVue.provincia = oVue.provincias.find(
          (x) => x.id_provincia == aJobber.id_provincia
        );
        oVue.getComunas();
        oVue.comuna = oVue.comunas.find(
          (x) => x.id_comuna == aJobber.id_comuna
        );
      } else {
        oVue.region = null;
        oVue.provincia = null;
        oVue.comuna = null;
      }
    },
    async checkDeleteJobberReq(oData) {
      let oVue = this;
      const confirmDeleteElement = await oVue.confirmDeleteJobberReq();
      if (confirmDeleteElement) {
        KTUtil.setLoading();
        const aDeleteResponse = await oVue.deleteJobberReq(oData);
        KTUtil.removeLoading();
        if (aDeleteResponse.result) {
          Swal.fire({
            title: "¡Enhorabuena!",
            text: "El elemento fue eliminado exitosamente",
            icon: "success",
          });
          switch (oData.type) {
            case "Comuna":
              oVue.coberturas = oVue.coberturas.filter((e) => e.id != oData.id);
              break;
            case "Competencia":
              oVue.competencias = oVue.competencias.filter(
                (e) => e.id != oData.id
              );
              break;
            case "Capacitacion":
              oVue.capacitaciones = oVue.capacitaciones.filter(
                (e) => e.id != oData.id
              );
              break;
            case "Antecedente":
              oVue.antecedentes = oVue.antecedentes.filter(
                (e) => e.id != oData.id
              );
              break;
            case "Cuenta":
              oVue.cuentas = oVue.cuentas.filter((e) => e.id != oData.id);
              break;
          }
        } else {
          Swal.fire({
            title: "Error al eliminar el elemento",
            text: aDeleteResponse.message,
            icon: "error",
          });
        }
      }
    },
    async confirmDeleteJobberReq(
      title = "¿Está Seguro que desea eliminar este elemento?"
    ) {
      let aDelete = await Swal.fire({
        title,
        text: "Los cambios no se pueden modificar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "NO",
        confirmButtonText: "SI",
      });
      if (aDelete.isConfirmed) {
        return true;
      }
      return false;
    },
    async deleteJobberReq(oData) {
      let oVue = this;
      const aResponse = oVue.$store.dispatch(DELETE_DETALLE_JOBBER, oData);

      return aResponse;
    },
  },
  watch: {
    //eslint-disable-next-line no-unused-vars
    fecha_nacimiento_original(val) {
      let oVue = this;
      oVue.aJobber.fecha_nacimiento = oVue.formatDate(
        oVue.fecha_nacimiento_original
      );
    },
    //eslint-disable-next-line no-unused-vars
    vigente_hasta_original(val) {
      let oVue = this;
      oVue.aJobber.vigente_hasta = oVue.formatDate(oVue.vigente_hasta_original);
    },
    //eslint-disable-next-line no-unused-vars
    fecha_inscripcion_original(val) {
      let oVue = this;
      oVue.aJobber.fecha_inscripcion = oVue.formatDate(
        oVue.fecha_inscripcion_original
      );
    },
    //eslint-disable-next-line no-unused-vars
    fecha_antecedentes_original(val) {
      let oVue = this;
      oVue.aJobber.fecha_antecedentes = oVue.formatDate(
        oVue.fecha_antecedentes_original
      );
    },
    //eslint-disable-next-line no-unused-vars
    fecha_contrato_original(val) {
      let oVue = this;
      oVue.aJobber.fecha_contrato = oVue.formatDate(
        oVue.fecha_contrato_original
      );
    },
    //eslint-disable-next-line no-unused-vars
    fecha_cobertura_original(val) {
      let oVue = this;
      oVue.fecha_cobertura = oVue.formatDate(oVue.fecha_cobertura_original);
    },
    //eslint-disable-next-line no-unused-vars
    fecha_competencia_original(val) {
      let oVue = this;
      oVue.fecha_competencia = oVue.formatDate(oVue.fecha_competencia_original);
    },
    //eslint-disable-next-line no-unused-vars
    fecha_capacitacion_original(val) {
      let oVue = this;
      oVue.fecha_capacitacion = oVue.formatDate(
        oVue.fecha_capacitacion_original
      );
    },
    //eslint-disable-next-line no-unused-vars
    fecha_antecedente_original(val) {
      let oVue = this;
      oVue.fecha_antecedente = oVue.formatDate(oVue.fecha_antecedente_original);
    },
  },
};
</script>
